#header,
#footer,
#banner,
#banner-video,
#nav--anchor,
#col--right,
#tools,
#skiplink,
#block--icons,
#syctom-presentation,
#event,
.home .link--type,
.gotop,
.figcaption--main,
.search-result--pager,
.quickmenu,
.button-openclose,
.tabPannel,
.link--icon,
.plr,
.tooltipster-base,
.news--container .block-1of3,
.swiper-button-next,
.swiper-button-prev,
#tx-solr-search-tabs,
.search--limit-width,
.tx-solr-nb-results-drop,
.search-result—-number,
.search-result--number--absolute,
.search—title,
.search—filters,
tx-solr-faceting,
video,
iframe {
    display: none;
}

.block-openclose .content {
    display: block;
}

#key-number .number li {
    margin-top: 0;
}

#container {
    overflow: visible;
}